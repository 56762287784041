// src/pages/LandingPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Importa el archivo de estilos

function LandingPage() {
  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1 className="landing-title">Bienvenido a Artemecatrónica</h1>
        <p className="landing-subtitle">Explora el mundo de la mecatrónica y el arte tecnológico.</p>
        <div className="landing-buttons">
          <Link to="/login" className="btn btn-primary">Iniciar Sesión</Link>
          <Link to="/register" className="btn btn-secondary">Registrarse</Link>
        </div>
      </header>
    </div>
  );
}

export default LandingPage;
