import React, { useState } from 'react';
import {
  loginUser,
  signInWithGoogle,
  signInWithGitHub,
} from '../firebase/authService';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser(email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error en inicio de sesión:', error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error al iniciar sesión con Google:', error);
    }
  };

  const handleGitHubLogin = async () => {
    try {
      await signInWithGitHub();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error al iniciar sesión con GitHub:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <h2>Iniciar Sesión</h2>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Ingresar</button>
      </form>
      <button type="button" className="google-button" onClick={handleGoogleLogin}>
        Iniciar sesión con Google
      </button>
      <button type="button" className="github-button" onClick={handleGitHubLogin}>
        Iniciar sesión con GitHub
      </button>
      <p>
        ¿No tienes una cuenta? <Link to="/register">Regístrate aquí</Link>
      </p>
    </div>
  );
};

export default Login;
