// src/pages/Dashboard.js

import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { logoutUser } from '../firebase/authService';
import UserInfo from '../components/UserInfo';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const { usuarioActual } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [logoutError, setLogoutError] = useState('');

  const handleLogout = async () => {
    setIsLoggingOut(true);
    setLogoutError('');
    try {
      await logoutUser();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
      setLogoutError('Ocurrió un error al cerrar sesión. Por favor, intenta nuevamente.');
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Artemecatrónica</h1>
        <button className="logout-button" onClick={handleLogout} disabled={isLoggingOut}>
          {isLoggingOut ? 'Cerrando...' : 'Cerrar Sesión'}
        </button>
      </header>
      <main className="dashboard-main">
        <h2>Bienvenido, {usuarioActual.email}</h2>
        {logoutError && <p className="error">{logoutError}</p>}
        <UserInfo />
      </main>
    </div>
  );
};

export default Dashboard;
