import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig'; // Importa la instancia de auth de Firebase
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [usuarioActual, setUsuarioActual] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUsuarioActual(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <AuthContext.Provider value={{ usuarioActual }}>
      {children}
    </AuthContext.Provider>
  );
};
