import React, { useState } from 'react';
import {
  registerUser,
  signInWithGoogle,
  signInWithGitHub,
} from '../firebase/authService';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await registerUser(email, password);
      navigate('/dashboard');
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-in-use':
          setError('El correo electrónico ya está en uso.');
          break;
        case 'auth/invalid-email':
          setError('El correo electrónico no es válido.');
          break;
        case 'auth/weak-password':
          setError('La contraseña es muy débil.');
          break;
        default:
          setError('Ocurrió un error al registrar el usuario.');
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error al registrarse con Google:', error);
    }
  };

  const handleGitHubLogin = async () => {
    try {
      await signInWithGitHub();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error al registrarse con GitHub:', error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleRegister}>
        <h2>Registrarse</h2>
        {error && <p className="error">{error}</p>}
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Registrarse</button>
      </form>
      <button type="button" className="google-button" onClick={handleGoogleLogin}>
        Registrarse con Google
      </button>
      <button type="button" className="github-button" onClick={handleGitHubLogin}>
        Registrarse con GitHub
      </button>
      <p>
        ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
      </p>
    </div>
  );
};

export default Register;
