// src/components/UserInfo.js

import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onChildAdded, get, child } from 'firebase/database';
import { app } from '../firebase/firebaseConfig';
import './UserInfo.css'; // Importa el archivo de estilos

const UserInfo = () => {
  const [usuario, setUsuario] = useState(null);
  const [error, setError] = useState(null);
  const [codigoRFID, setCodigoRFID] = useState(null);

  useEffect(() => {
    const db = getDatabase(app);
    const lecturasRef = ref(db, '/lecturas');
  
    const unsubscribe = onChildAdded(lecturasRef, async (snapshot) => {
      const codigo_rfid = snapshot.val();
      console.log('Nuevo código RFID escaneado:', codigo_rfid);
  
      try {
        // Obtener la información del usuario desde /usuarios/{codigo_rfid}
        const usuarioSnapshot = await get(child(ref(db), `/usuarios/${codigo_rfid}`));
        if (usuarioSnapshot.exists()) {
          const usuarioData = usuarioSnapshot.val();
          console.log('Información del usuario:', usuarioData);
          setUsuario(usuarioData);
          setCodigoRFID(codigo_rfid);
          setError(null);
        } else {
          console.log('Usuario no encontrado para el código RFID:', codigo_rfid);
          setError('Usuario no encontrado');
          setUsuario(null);
          setCodigoRFID(codigo_rfid);
        }
      } catch (err) {
        console.error('Error al obtener la información del usuario:', err);
        setError('Error al obtener la información del usuario');
        setUsuario(null);
        setCodigoRFID(codigo_rfid);
      }
    });
  
    // Cleanup al desmontar el componente
    return () => unsubscribe();
  }, []);

  
  return (
    <div>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {usuario ? (
        
        <div className="user-info">
          <h3>Información del Usuario</h3>
          <p><strong>Código RFID:</strong> {codigoRFID}</p>
          <p><strong>Nombre:</strong> {usuario.nombre}</p>
          <p><strong>Apellido:</strong> {usuario.apellido}</p>
          <p><strong>Correo Electrónico:</strong> {usuario.correo_electronico}</p>
        </div>
      ) : (
        <p>Esperando escaneo de tarjeta RFID...</p>
      )}
    </div>
  );
};

export default UserInfo;
